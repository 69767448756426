.app__header {
  background-color: var(--color-black);
  display: flex;
  justify-content: center;
  align-items: center; 
  text-align: center;
  padding: 6rem 2rem;
  height: 100vh; 
}

.app__header + * {
  margin-top: 4rem; 
}

.app__header-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; 
}

.app__header-h1 {
  font-family: var(--font-base);
  color: var(--color-red);
  letter-spacing: 0.1em;
  text-transform: uppercase;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  font-size: 2.5rem; 
}

.app__header-description {
  color: #d1d1d1;
  font-size: 1rem;
  line-height: 1.8;
  margin: 1.5rem 0;
  max-width: 800px; 
}

.custom__button {
  background-color: var(--color-crimson);
  color: var(--color-black);
  font-family: var(--font-base);
  font-weight: 700;
  letter-spacing: 0.04em;
  line-height: 28px;
  font-size: 16px;
  padding: 0.5rem 1.5rem;
  border-radius: 1px;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.custom__button:hover {
  background-color: var(--color-grey);
  color: var(--color-white);
  transform: scale(1.05);
}

@media screen and (min-width: 768px) {
  .app__header-h1 {
    font-size: 4rem; 
  }

  .app__header-description {
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 1200px) {
  .app__header-h1 {
    font-size: 5rem; 
  }

  .app__header-description {
    font-size: 1.4rem;
  }
}
