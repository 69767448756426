body {
  font-family: "Inter", sans-serif;
  background-color: black;
  color: white;
}

.menu-container {
  max-width: 1640px;
  margin: 0 auto;
  padding: 1.5rem;
  background-color: black;
  color: white;
}

.menu-title {
  text-align: center;
  font-size: 2.5rem;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 2rem;
}

.category-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
}

.menu-button {
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  font-weight: 600;
  border: 2px solid #f8f8f8;
  border-radius: 8px;
  background: transparent;
  color: #ffffff;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.menu-button:hover {
  background-color: #e3342f;
  color: white;
}

.menu-button.active {
  background-color: #e3342f;
  color: white;
}

.menu-grid {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(1, 1fr);
}

@media screen and (min-width: 640px) {
  .menu-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 1024px) {
  .menu-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 1280px) {
  .menu-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

.card {
  background-color: #1a1a1a;
  color: #ffffff;
  border: 1px solid #2e2e2e;
  border-radius: 8px;
  padding: 16px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.card:hover {
  transform: scale(1.03);
  box-shadow: 0 4px 15px rgba(255, 255, 255, 0.1);
}

.card h2 {
  font-size: 1.2rem;
  font-weight: bold;
  color: #ffffff;
}

.card p {
  margin: 8px 0;
  font-size: 0.9rem;
  color: #d1d1d1;
}

.card .price {
  font-size: 1.5rem;
  font-weight: bold;
  color: #ffffff;
}
