.info__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 800px; 
  margin: 0 auto;
  padding: 2rem 1rem;
}

.info__heading {
  font-family: var(--font-base); 
  font-size: 2.5rem;
  color: var(--color-red);
  letter-spacing: 0.05em;
  margin-bottom: 1.5rem;
}

.info__text {
  font-family: var(--font-alt); 
  font-size: 1rem;
  color: var(--color-white);
  margin-bottom: 2rem;
}

.info__map {
  width: 100%;
  height: 300px;
  border: none;
  border-radius: 8px;
  margin-bottom: 2rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); 
}

.info__hours-heading {
  font-family: var(--font-base);
  font-size: 2rem;
  color: var(--color-red);
  margin: 2rem 0 1rem;
}

.info__hours {
  font-family: var(--font-alt);
  font-size: 1rem;
  color: var(--color-white);
  margin-bottom: 1rem;
}

.info__note {
  font-family: var(--font-alt);
  font-size: 0.9rem;
  color: var(--color-grey);
  margin-bottom: 2rem;
}

.info__button {
  font-family: var(--font-alt);
  font-size: 1rem;
  color: var(--color-white);
  background-color: var(--color-red);
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.info__button:hover {
  background-color: var(--color-grey);
  color: var(--color-black);
}
