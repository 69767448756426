.app__navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--color-black);
  padding: 0 1rem;
  position: relative;
  z-index: 10;
}

.app__navbar-logo {
  display: flex;
  align-items: center;
}

.app__navbar-logo img {
  width: 100px;
  object-fit: contain;
}

.app__navbar-links {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
}

.app__navbar-links li {
  margin: 0 1rem;
  cursor: pointer;
  position: relative;
  font-size: 1rem;
  color: var(--color-white);
  transition: all 0.3s ease-in-out;
}

.app__navbar-links li:hover {
  color: var(--color-red);
  border-bottom: 2px solid var(--color-red);
}

.app__navbar-order {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.app__navbar-order a {
  display: flex;
  align-items: center;
  color: #ffffff;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s ease, border-bottom 0.3s ease;
}

.app__navbar-order a:hover span {
  color: var(--color-red);
}

.app__navbar-order div {
  width: 1px;
  height: 30px;
  background-color: var(--color-grey);
}

.app__navbar-order img {
  width: 25px;
  margin-right: 8px;
}

.app__navbar-smallscreen {
  display: none;
}

.app__navbar-smallscreen_overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: var(--color-black);
  z-index: 999;
  transition: opacity 0.5s ease;
}

.app__navbar-smallscreen_overlay .overlay__close {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 27px;
  color: var(--color-golden);
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}

.app__navbar-smallscreen_overlay .overlay__close:hover {
  color: var(--color-red);
}

.app__navbar-smallscreen_links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.app__navbar-smallscreen_links li {
  margin: 2rem 0;
  font-size: 2rem;
  color: var(--color-red);
  text-align: center;
  cursor: pointer;
  font-family: var(--font-base);
  transition: color 0.3s ease-in-out;
}

.app__navbar-smallscreen_links li:hover {
  color: var(--color-white);
}

@media screen and (min-width: 2000px) {
  .app__navbar-logo img {
    width: 210px;
  }
}

@media screen and (max-width: 1150px) {
  .app__navbar-links {
    display: none;
  }

  .app__navbar-smallscreen {
    display: flex;
  }
}

@media screen and (max-width: 650px) {
  .app__navbar {
    padding: 1rem;
  }

  .app__navbar-logo img {
    width: 110px;
  }
}
