.app__footer {
  width: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--color-black);
  padding: 2rem 1rem;
  color: var(--color-white);
}

.app__footer-links {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  text-align: center;
  padding: 1rem 0;
  gap: 2rem;
}

.app__footer-links_contact,
.app__footer-links_quotes {
  flex: 1;
  min-width: 280px;
}

.app__footer-headtext {
  font-family: var(--font-base);
  color: var(--color-red);
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.app__footer-address,
.app__footer-number,
.app__footer-quote {
  font-family: var(--font-alt);
  color: var(--color-white);
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 0.5rem;
}

.app__footer-number a {
  color: var(--color-red);
  text-decoration: none;
  transition: color 0.3s ease;
}

.app__footer-number a:hover {
  color: var(--color-grey);
}

.app__footer-quote {
  font-style: italic;
}

.app__footer-icons {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.app__footer-icons img {
  width: 30px;
  height: 30px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.app__footer-icons img:hover {
  transform: scale(1.1);
}

.app__footer-copyright {
  text-align: center;
  margin-top: 2rem;
  font-size: 0.9rem;
  font-family: var(--font-alt);
  color: var(--color-grey);
}

.developer {
  text-decoration: none;
  font-family: var(--font-alt);
  color: var(--color-white);
  transition: color 0.3s ease;
}

.developer:hover {
  color: var(--color-red);
}
